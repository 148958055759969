import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';

// Components
import { PressArticle } from '../components/press/press-article';

const WorkContainer = styled.div`
  position: relative;

  padding: 60px 0 0 0;
  margin: 0 auto;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 45px;
  grid-auto-flow: dense;

  @media (min-width: 1500px) {
    max-width: 1400px;
  }

  @media (max-width: 1499px) {
    max-width: 1050px;
  }

  @media (max-width: 767px) {
    padding: 15px 0 0 0;

    grid-template-columns: 1fr 1fr;
    grid-gap: 15px;
  }
`;

const Press = ({ data }) => {
  const articles = data.prismicPress.data.body.map((article, index) => (
    <PressArticle
      article={article}
      index={index}
      key={`article_${article.id}_${index}`}
    />
  ));

  return (
    <>
      <Helmet title={`Press - Treatment Studio`} />
      <WorkContainer>{articles}</WorkContainer>
    </>
  );
};

export default withPrismicPreview(Press);

export const query = graphql`
  {
    prismicPress {
      data {
        body {
          ... on PrismicPressDataBodyPressArticle {
            id
            slice_type
            primary {
              article_title {
                text
              }
              article_subtitle {
                text
              }
              image {
                alt
                url(imgixParams: { auto: "format" })
                dimensions {
                  width
                  height
                }
              }
              image_size
              link {
                url
              }
            }
          }
        }
      }
    }
  }
`;
